import "./App.css";
import * as React from "react";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import { useState, useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Card from "@mui/material/Card";
import joosed_labs from "./joosed_labs.png";
import joosed_logo from "./joosed_logo.png";
import joosed_labs2 from "./joosed_labs2.png";
import LooksOneIcon from "@mui/icons-material/LooksOne";
import LooksTwoIcon from "@mui/icons-material/LooksTwo";
import Looks3Icon from "@mui/icons-material/Looks3";
import Looks4Icon from "@mui/icons-material/Looks4";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function App() {
  return (
    <div className="App">
      <Container>
        <header className="App-header">
          <img src={joosed_logo} className="App-logo" alt="logo" />

          <img src={joosed_labs} className="App-lab" alt="logo" />

          <img src={joosed_labs2} className="App-lab" alt="logo" />
          <Card>Copyright Joosed Labs 2024</Card>
        </header>
      </Container>
    </div>
  );
}

export default App;
